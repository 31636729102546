import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import "../../i18n";

import { FaStore } from "react-icons/fa6";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { TbDeviceDesktopAnalytics, TbLogout } from "react-icons/tb";

import { ReactComponent as ChannelsIcon } from "../../assets/category.svg";
import { ReactComponent as BisoIntelligenceIcon } from "../../assets/chip.svg";
import connectorLastSearchIcon from "../../assets/connectors_last_search.svg";
import { ReactComponent as SalesPanelIcon } from "../../assets/dashboard.svg";
import { ReactComponent as AudienceIcon } from "../../assets/group.svg";
import { ReactComponent as ProductsAndLogisticsIcon } from "../../assets/leaderdashbaord.svg";
import smallBisoLogo from "../../assets/logo_biso_v2.png";
import { ReactComponent as MobileMenuIcon } from "../../assets/menu.svg";
import originalBisoLogo from "../../assets/originalLogo.svg";
import { ReactComponent as BisoInsightsIcon } from "../../assets/rocket.svg";
import { ReactComponent as FavoritesIcon } from "../../assets/star.svg";

import { ReactComponent as ChannelsIconFilled } from "../../assets/category_filled.svg";
import { ReactComponent as BisoIntelligenceIconFilled } from "../../assets/chip_filled.svg";
import { ReactComponent as SalesPanelIconFilled } from "../../assets/dashboard_filled.svg";
import { ReactComponent as AudienceIconFilled } from "../../assets/group_filled.svg";
import { ReactComponent as ProductsAndLogisticsIconFilled } from "../../assets/leaderdashbaord_filled.svg";
import { ReactComponent as BisoInsightsIconFilled } from "../../assets/rocket_filled.svg";
import { ReactComponent as FavoritesIconFilled } from "../../assets/star_filled.svg";

import { routes } from "../../routes";

import { AuthContext } from "../../contexts";

import { featureIsEnabled, featureType, setCompanyFeatures } from "../../options/feature";
import { companyHasIntelipostConnector } from "../../utils/loginUtils";

// import ModalPageBisoAdvertise from '../CustomModal/ModalPageBisoAdvertise';
import { SupportButton } from "../SupportButton";
import { BisoTooltip } from "../Tooltip";
import FirstSteps from "./../FirstSteps";
import { InfoBarFacebookOAuthReconnect, InfoBarInstallApp, InfoBarProfileLogin } from "./../InfoBar";

import {
  ButtonCloseMenu,
  ButtonFavorite,
  ButtonLogout,
  ButtonOpenMenu,
  Logo,
  MenuIcon,
  MenuItemTagDeprecated,
  MenuItemTagNewFeature,
  NoFavorites,
  SidebarActions,
  SidebarContainer,
  SidebarHeader,
  SidebarItem,
  SidebarMenu,
  SubMenuContainer,
  SubMenuItem,
  SubMenuItemContainer,
  SubMenuItemTagDeprecated,
  SubMenuItemTagNewFeature,
} from "./styled";

import AppBar from "./AppBar";
import { ButtonMobileMenu, IconContainer, IconImage, Profile } from "./AppBar/styled";

const MENU_EXPANDED_WIDTH = 306;
const MENU_WIDTH = 59;
const MOBILE_BREAKPOINT = 1200;

const Menu = () => {
  const { t } = useTranslation();

  const PAGES = {
    SALES_PANEL: t('menu.sales_dashboard'),
    CHANNELS: t('menu.channels'),
    AUDIENCE: t('menu.audience'),
    PRODUCTS_AND_LOGISTICS: t('menu.products_and_logistics'),
    BISO_INTELLIGENCE: t('menu.biso_intelligence'),
    BISO_INSIGHTS: t('menu.biso_insights'),
    SETTINGS: t('appBar.settings'),
    FAVORITE: t('menu.favorites'),
    SERVICE_MARKETPLACE: t('menu.service_marketplace'),
  };

  const pageSettings = {
    notifications: {
      name: t('menu.notifications'),
      route: routes.notifications,
      hierarchyDetails: `${t('menu.notifications')}`,
      keywords: ["notifications", "notificações", "notificaciones"],
      isSubCategory: false,
      category: null,
      isNew: false,
      isDeprecated: false,
    },
    serviceMarketplace: {
      name: t('menu.service_marketplace'),
      route: routes.serviceMarketplace,
      hierarchyDetails: `${t('menu.service_marketplace')}`,
      keywords: ["marketplace", "serviços", "services", "servicios"],
      isSubCategory: false,
      category: null,
      isNew: false,
      isDeprecated: false,
    },
    profile: {
      name: t('menu.profile'),
      route: routes.profile,
      hierarchyDetails: `${t('appBar.settings')} > ${t('menu.profile')}`,
      keywords: ["profile", "perfil", "configurações", "settings", "perfil", "configuraciones"],
      isSubCategory: true,
      category: PAGES.SETTINGS,
      isNew: false,
      isDeprecated: false,
    },
    preferences: {
      name: t('appBar.preferences'),
      route: routes.preferences,
      hierarchyDetails: `${t('appBar.settings')} > ${t('appBar.preferences')}`,
      keywords: ["preferences", "preferências", "preferencias"],
      isSubCategory: true,
      category: PAGES.SETTINGS,
      isNew: false,
      isDeprecated: false,
    },
    bisoInsights: {
      name: t('menu.biso_insights'),
      route: routes.bisoInsights,
      hierarchyDetails: `${t('menu.biso_insights')}`,
      keywords: ["biso insights", "insights", "biso"],
      isSubCategory: false,
      category: null,
      isNew: false,
      isDeprecated: false,
    },
    dataSyncInfo: {
      name: t('appBar.data_update'),
      route: routes.dataSyncInfo,
      hierarchyDetails: `${t('appBar.data_update')}`,
      keywords: [
        "data sync",
        "sincronização",
        "atualização",
        "data",
        "dados",
        "sync",
        "atualização de dados",
        "sincronización de datos",
        "sincronización",
        "actualización",
        "datos",
        "datos",
        "sincronización",
        "actualización de datos",
      ],
      isSubCategory: false,
      category: null,
      isNew: false,
      isDeprecated: false,
    },
    users: {
      name: t('appBar.manage_users'),
      route: routes.users,
      hierarchyDetails: `${t('appBar.settings')} > ${t('appBar.manage_users')}`,
      keywords: ["users", "usuários", "usuarios"],
      isSubCategory: true,
      category: PAGES.SETTINGS,
      isNew: false,
      isDeprecated: false,
    },
    connectionsAndChannels: {
      name: t('appBar.connections_and_channels'),
      route: routes.connectionsAndChannels,
      hierarchyDetails: `${t('appBar.settings')} > ${t('appBar.connections_and_channels')}`,
      keywords: ["connections", "conexões", "channels", "canais", "conectores", "conexiones", "canales", "conectores"],
      isSubCategory: true,
      category: PAGES.SETTINGS,
      isNew: false,
      isDeprecated: false,
    },
    alerts: {
      name: t('menu.alerts'),
      route: routes.alerts,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.alerts')}`,
      keywords: ["alerts", "alertas", "monitoramento", "monitoring", "alertas", "monitoreo"],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: false,
      isDeprecated: false,
    },
    mediaPlanner: {
      name: t('menu.media_planner'),
      route: routes.mediaPlanner,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.media_planner')}`,
      keywords: [
        "media planner",
        "media",
        "planner",
        "planejamento",
        "metas",
        "goals",
        "mídia",
        "google analytics",
        "medios",
        "planificador",
        "objetivos",
      ],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: false,
      isDeprecated: false,
    },
    goalsManager: {
      name: t('menu.goal_management'),
      route: routes.goalsManager,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.goal_management')}`,
      keywords: [
        "media",
        "mídia",
        "planner",
        "planejamento",
        "metas",
        "goals",
        "google analytics",
        "channels",
        "canais",
        "medios",
        "planificador",
        "objetivos",
        "canales",
      ],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: true,
      isDeprecated: false,
    },
    adTextCreator: {
      name: t('menu.ad_generator'),
      route: routes.adTextCreator,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.ad_generator')}`,
      keywords: [
        "ad text creator", 
        "anúncio",
        "criador",
        "gerador",
        "ads",
        "generator",
        "creator",
        "anuncio",
        "creador",
        "generador",
        "ads",
        "generator",
        "creator"
      ],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: false,
      isDeprecated: false,
    },
    smartReportMinimumStock: {
      name: t('menu.smart_report_minimum_stock'),
      route: routes.smartReportMinimumStock,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.smart_report_minimum_stock')}`,
      keywords: [
        "smart report", 
        "estoque mínimo", 
        "stock", 
        "minimum stock", 
        "report", 
        "relatório",
        "informe inteligente",
        "stock mínimo",
        "informe",
        "report"
      ],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: false,
      isDeprecated: false,
    },
    anomalies: {
      name: t('menu.anomaly_detection'),
      route: routes.anomalies,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.anomaly_detection')}`,
      keywords: [
        "anomalies",
        "anomalias",
        "monitoramento",
        "monitoring",
        "detecção",
        "detection",
        "identificação",
        "identification",
        "google analytics",
        "anomalías",
        "monitorización",
        "detección",
        "identificación"
      ],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: false,
      isDeprecated: false,
    },
    customerBehavior: {
      name: t('menu.customer_behavior'),
      route: routes.customerBehavior,
      hierarchyDetails: `${t('menu.biso_intelligence')} > ${t('menu.customer_behavior')}`,
      keywords: [
        "customer behavior", 
        "comportamento", 
        "customer", 
        "comportamento do cliente", 
        "cliente",
        "comportamiento",
        "comportamiento del cliente"
      ],
      isSubCategory: true,
      category: PAGES.BISO_INTELLIGENCE,
      isNew: false,
      isDeprecated: false,
    },
    goalsOverview: {
      name: t('menu.goals_overview'),
      route: routes.goalsOverview,
      hierarchyDetails: `${t('menu.sales_dashboard')} > ${t('menu.goals_overview')}`,
      keywords: [
        "macro view",
        "visão geral",
        "metas",
        "goals",
        "google analytics",
        "ecommerce",
        "vendas",
        "sales",
        "overview",
        "channels",
        "canais",
        "visión general",
        "objetivos",
        "ventas",
        "visión general",
        "canales"
      ],
      isSubCategory: true,
      category: PAGES.SALES_PANEL,
      isNew: false,
      isDeprecated: false,
    },
    goalsEvolution: {
      name: t('menu.goals_progress'),
      route: routes.goalsEvolution,
      hierarchyDetails: `${t('menu.sales_dashboard')} > ${t('menu.goals_progress')}`,
      keywords: [
        "goals evolution",
        "evolução",
        "metas",
        "goals",
        "google analytics",
        "ecommerce",
        "vendas",
        "sales",
        "overview",
        "channels",
        "canais",
        "evolución",
        "objetivos",
        "ventas",
        "visión general",
        "canales"
      ],
      isSubCategory: true,
      category: PAGES.SALES_PANEL,
      isNew: true,
      isDeprecated: false,
    },
    marketplaceOverview: {
      name: t('menu.marketplace_overview'),
      route: routes.marketplaceOverview,
      hierarchyDetails: `${t('menu.sales_dashboard')} > ${t('menu.marketplace_overview')}`,
      keywords: [
        "marketplace overview",
        "visão geral",
        "marketplace",
        "overview",
        "canais",
        "channels",
        "sales",
        "vendas",
        "visión general",
        "canales",
        "ventas"
      ],
      isSubCategory: true,
      category: PAGES.SALES_PANEL,
      isNew: false,
      isDeprecated: false,
    },
    operationOverview: {
      name: t('menu.operations_overview'),
      route: routes.operationOverview,
      hierarchyDetails: `${t('menu.sales_dashboard')} > ${t('menu.operations_overview')}`,
      keywords: [
        "operation overview",
        "visão geral",
        "operação",
        "overview",
        "canais",
        "channels",
        "sales",
        "vendas",
        "marketplace",
        "ecommerce",
        "visión general",
        "operación",
        "canales",
        "ventas",
      ],
      isSubCategory: true,
      category: PAGES.SALES_PANEL,
      isNew: false,
      isDeprecated: false,
    },
    paymentOverview: {
      name: t("menu.payment_overview"),
      route: routes.paymentOverview,
      hierarchyDetails: `${t('menu.sales_dashboard')} > ${t('menu.payment_overview')}`,
      keywords: [
        "pagamento",
        "payment overview",
        "pago",
        "method",
        "método",
        "forms",
        "formas",
        "marketplace",
        "ecommerce",
        "sales",
        "vendas",
        "ventas",
        "overview",
        "visão geral de pagamento",
        "resumen de pagos",
      ],
      isSubCategory: true,
      category: PAGES.SALES_PANEL,
      isNew: true,
      isDeprecated: false,
    },
    paidMediaOverview: {
      name: t('menu.paid_media_overview'),
      route: routes.paidMediaOverview,
      hierarchyDetails: `${t('menu.channels')} > ${t('menu.paid_media_overview')}`,
      keywords: [
        "media overview",
        "visão geral",
        "mídia",
        "media",
        "overview",
        "canais",
        "channels",
        "mídia paga",
        "paid media",
        "google analytics",
        "visión general",
        "medios",
        "canales",
        "mídia pagada"
      ],
      isSubCategory: true,
      category: PAGES.CHANNELS,
      isNew: false,
      isDeprecated: false,
    },
    paidMediaCampaigns: {
      name: t('menu.paid_media_campaigns'),
      route: routes.paidMediaCampaigns,
      hierarchyDetails: `${t('menu.channels')} > ${t('menu.paid_media_campaigns')}`,
      keywords: [
        "media campaign",
        "campanhas",
        "campaigns",
        "mídia",
        "media",
        "canais",
        "channels",
        "mídia paga",
        "paid media",
        "funil",
        "funnel",
        "google analytics",
        "campañas",
        "medios",
        "canales",
        "mídia pagada",
        "embudo"
      ],
      isSubCategory: true,
      category: PAGES.CHANNELS,
      isNew: false,
      isDeprecated: false,
    },
    allChannel: {
      name: t('menu.channels_summary'),
      route: routes.allChannel,
      hierarchyDetails: `${t('menu.channels')} > ${t('menu.channels_summary')}`,
      keywords: [
        "channels", 
        "canais", 
        "resumo", 
        "overview", 
        "google analytics", 
        "campanhas", 
        "campaigns",
        "canales",
        "resumen",
        "campañas"
      ],
      isSubCategory: true,
      category: PAGES.CHANNELS,
      isNew: false,
      isDeprecated: false,
    },
    mailMarketing: {
      name: t('menu.email_marketing'),
      route: routes.mailMarketing,
      hierarchyDetails: `${t('menu.channels')} > ${t('menu.email_marketing')}`,
      keywords: [
        "mail marketing", 
        "e-mail", 
        "email", 
        "marketing", 
        "canais", 
        "channels", 
        "google analytics",
        "canales"
      ],
      isSubCategory: true,
      category: PAGES.CHANNELS,
      isNew: false,
      isDeprecated: false,
    },
    audienceOverview: {
      name: t('menu.audience_overview'),
      route: routes.audienceOverview,
      hierarchyDetails: `${t('menu.audience')} > ${t('menu.audience_overview')}`,
      keywords: [
        "audience overview",
        "overview",
        "visão geral",
        "audiência",
        "audience",
        "novos clientes",
        "new customers",
        "recompradores",
        "rebuyers",
        "ltv",
        "cac",
        "ecommerce",
        "vendas",
        "sales",
        "marketplace",
        "visión general",
        "audiencia",
        "nuevos clientes",
        "recompradores",
        "ventas"
      ],
      isSubCategory: true,
      category: PAGES.AUDIENCE,
      isNew: false,
      isDeprecated: false,
    },
    audienceGeolocation: {
      name: t('menu.geolocation'),
      route: routes.audienceGeolocation,
      hierarchyDetails: `${t('menu.audience')} > ${t('menu.geolocation')}`,
      keywords: [
        "geolocation",
        "geolocalização",
        "audiência",
        "audience",
        "ecommerce",
        "vendas",
        "sales",
        "marketplace",
        "geolocalización",
        "audiencia",
        "ventas"
      ],
      isSubCategory: true,
      category: PAGES.AUDIENCE,
      isNew: false,
      isDeprecated: false,
    },
    audienceDecil: {
      name: t('menu.decile'),
      route: routes.audienceDecil,
      hierarchyDetails: `${t('menu.audience')} > ${t('menu.decile')}`,
      keywords: [
        "decil", 
        "audiência", 
        "audience", 
        "ecommerce", 
        "vendas", 
        "sales", 
        "marketplace",
        "audiencia",
        "ventas"
      ],
      isSubCategory: true,
      category: PAGES.AUDIENCE,
      isNew: false,
      isDeprecated: false,
    },
    audienceRfv: {
      name: t('menu.rfv'),
      route: routes.audienceRfv,
      hierarchyDetails: `${t('menu.audience')} > ${t('menu.rfv')}`,
      keywords: [
        "rfv",
        "audiência",
        "audience",
        "ecommerce",
        "vendas",
        "sales",
        "marketplace",
        "recência",
        "frequência",
        "valor",
        "audiencia",
        "ventas",
        "recencia",
        "frecuencia",
        "valor"
      ],
      isSubCategory: true,
      category: PAGES.AUDIENCE,
      isNew: false,
      isDeprecated: false,
    },
    audienceCohort: {
      name: t('menu.cohort'),
      route: routes.audienceCohort,
      hierarchyDetails: `${t('menu.audience')} > ${t('menu.cohort')}`,
      keywords: [
        "cohort",
        "audiência",
        "audience",
        "ecommerce",
        "vendas",
        "sales",
        "marketplace",
        "retenção",
        "retention",
        "ciclo de compra",
        "purchase cycle",
        "cohorte",
        "audiencia",
        "ventas",
        "retención",
        "ciclo de compra"
      ],
      isSubCategory: true,
      category: PAGES.AUDIENCE,
      isNew: false,
      isDeprecated: false,
    },
    productPerformance: {
      name: t('menu.product_performance'),
      route: routes.productPerformance,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.product_performance')}`,
      keywords: [
        "product performance",
        "desempenho de produtos",
        "ecommerce",
        "vendas",
        "sales",
        "marketplace",
        "produtos",
        "products",
        "estoque",
        "stock",
        "categorias",
        "categories",
        "desempeño de productos",
        "ventas",
        "productos",
        "stock",
        "categorías"
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: false,
      isDeprecated: false,
    },
    ProductbehaviorAbcMetrics: {
      name: t('menu.abc_curve_of_products'),
      route: routes.productBehaviorCurveAbc,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.abc_curve_of_products')}`,
      keywords: [
        "ecommerce",
        "vendas",
        "sales",
        "marketplace",
        "produtos",
        "products",
        "curva",
        "abc",
        "curve",
        "ventas",
        "productos",
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: true,
      isDeprecated: false,
    },
    logisticIntelipostMostCommunDeliveryTime: {
      name: t('menu.delivery_times'),
      route: routes.logisticIntelipostMostCommunDeliveryTime,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.delivery_times')}`,
      keywords: [
        "delivery time",
        "horários de entrega",
        "ecommerce",
        "marketplace",
        "logística",
        "logistics",
        "transportadora",
        "carrier",
        "entrega",
        "delivery",
        "frete",
        "shipping",
        "tiempo de entrega",
        "transportista",
        "entrega",
        "envío"
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: false,
      isDeprecated: false,
    },
    logisticAddressesWithDeliveryFailures: {
      name: t('menu.delivery_issues'),
      route: routes.logisticAddressesWithDeliveryFailures,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.delivery_issues')}`,
      keywords: [
        "delivery failures",
        "falhas de entrega",
        "ecommerce",
        "marketplace",
        "logística",
        "logistics",
        "transportadora",
        "carrier",
        "entrega",
        "delivery",
        "frete",
        "shipping",
        "fallos de entrega",
        "transportista",
        "entrega",
        "envío"
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: false,
      isDeprecated: false,
    },
    logisticShippingMethodEvolution: {
      name: t('menu.shipping_methods'),
      route: routes.logisticShippingMethodEvolution,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.shipping_methods')}`,
      keywords: [
        "shipping method",
        "métodos de envio",
        "ecommerce",
        "marketplace",
        "logística",
        "logistics",
        "transportadora",
        "carrier",
        "entrega",
        "delivery",
        "frete",
        "shipping",
        "métodos de envío",
        "transportista",
        "entrega",
        "envío"
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: false,
      isDeprecated: false,
    },
    logisticAverageShippingCostEvolution: {
      name: t('menu.freight_progress'),
      route: routes.logisticAverageShippingCostEvolution,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.freight_progress')}`,
      keywords: [
        "average shipping cost",
        "custo médio de envio",
        "ecommerce",
        "marketplace",
        "logística",
        "logistics",
        "transportadora",
        "carrier",
        "entrega",
        "delivery",
        "frete",
        "shipping",
        "costo promedio de envío",
        "transportista",
        "entrega",
        "envío"
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: false,
      isDeprecated: false,
    },
    logisticShippingTypeEvolution: {
      name: t('menu.types_of_freight'),
      route: routes.logisticShippingTypeEvolution,
      hierarchyDetails: `${t('menu.products_and_logistics')} > ${t('menu.types_of_freight')}` ,
      keywords: [
        "shipping type",
        "tipos de frete",
        "ecommerce",
        "marketplace",
        "logística",
        "logistics",
        "transportadora",
        "carrier",
        "entrega",
        "delivery",
        "frete",
        "shipping",
        "tipos de envío",
        "transportista",
        "entrega",
        "envío"
      ],
      isSubCategory: true,
      category: PAGES.PRODUCTS_AND_LOGISTICS,
      isNew: false,
      isDeprecated: false,
    },
  };

  const deprecatedPagesSettings = {
    oldDashboard: {
      name: "Dashboard antigo",
      route: routes.oldDashboard,
      hierarchyDetails: `Dashboard antigo`,
      keywords: ["dashboard"],
      isSubCategory: false,
      category: null,
      isNew: false,
      isDeprecated: true,
    },
  };

  const menuItems = [
    {
      name: PAGES.SALES_PANEL,
      icon: (isSelected) => (isSelected ? <SalesPanelIconFilled /> : <SalesPanelIcon />),
      hasSubCategories: true,
    },
    {
      name: PAGES.CHANNELS,
      icon: (isSelected) => (isSelected ? <ChannelsIconFilled /> : <ChannelsIcon />),
      hasSubCategories: true,
    },
    {
      name: PAGES.AUDIENCE,
      icon: (isSelected) => (isSelected ? <AudienceIconFilled /> : <AudienceIcon />),
      hasSubCategories: true,
    },
    {
      name: PAGES.PRODUCTS_AND_LOGISTICS,
      icon: (isSelected) => (isSelected ? <ProductsAndLogisticsIconFilled /> : <ProductsAndLogisticsIcon />),
      hasSubCategories: true,
    },
    {
      name: PAGES.BISO_INTELLIGENCE,
      icon: (isSelected) => (isSelected ? <BisoIntelligenceIconFilled /> : <BisoIntelligenceIcon />),
      hasSubCategories: true,
    },
    {
      name: PAGES.BISO_INSIGHTS,
      icon: (isSelected) => (isSelected ? <BisoInsightsIconFilled /> : <BisoInsightsIcon />),
      hasSubCategories: false,
    },
    { name: PAGES.SERVICE_MARKETPLACE, icon: (_) => <FaStore />, hasSubCategories: false },
    {
      name: PAGES.FAVORITE,
      icon: (isSelected) => (isSelected ? <FavoritesIconFilled /> : <FavoritesIcon />),
      hasSubCategories: true,
    },
    {
      name: deprecatedPagesSettings.oldDashboard.name,
      icon: (_) => <TbDeviceDesktopAnalytics />,
      hasSubCategories: false,
    },
  ];

  const { user, handleSignOut } = useContext(AuthContext);

  const navigate = useNavigate();

  const [mobileModeActive, setMobileModeActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const [profileInitials, setProfileInitials] = useState("");

  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [currentSubCategoriesMenuItem, setCurrentSubCategoriesMenuItem] = useState([]);
  const [enabledAdTextCreatorFeature, setEnabledAdTextCreatorFeature] = useState(false);
  const [enabledSmartReportMinimumStockFeature, setEnabledSmartReportMinimumStockFeature] = useState(false);
  const [showOldDashboardMenuItem, setShowOldDashboardMenuItem] = useState(false);
  const [showIntelipostAnalysis, setShowIntelipostAnalysis] = useState(false);

  const [lastScreenName, setLastScreenName] = useState("");
  const [lastScreenRoute, setLastScreenRoute] = useState("");

  const [favoritesPages, setFavoritesPages] = useState([]);

  const navigateToPage = (route) => {
    const currentPath = window.location.pathname;

    const pageSetting = Object.values(pageSettings).find((page) => page.route === currentPath);
    setLastScreenName(pageSetting ? pageSetting.name : "");
    setLastScreenRoute(currentPath);

    navigate(route);

    setIsExpanded(false);
    setHoveredMenuItem(null);
    setCurrentSubCategoriesMenuItem([]);
  };

  const returnToLastScreen = () => {
    if (lastScreenRoute) {
      navigate(lastScreenRoute);

      setLastScreenName("");
      setLastScreenRoute("");
      setIsExpanded(false);
      setHoveredMenuItem(null);
      setCurrentSubCategoriesMenuItem([]);
    }
  };

  const addPageToFavorites = (pageName) => {
    setFavoritesPages([...favoritesPages, pageName]);
    localStorage.setItem("favoritesPages", JSON.stringify([...favoritesPages, pageName]));
  };

  const removePageFromFavorites = (pageName) => {
    const favoritesPagesUpdated = favoritesPages.filter((page) => page !== pageName);
    setFavoritesPages(favoritesPagesUpdated);
    localStorage.setItem("favoritesPages", JSON.stringify(favoritesPagesUpdated));
  };

  const setFavoritesPagesFromLocalStorage = useCallback((_) => {
    const favoritesPages = localStorage.getItem("favoritesPages");
    if (favoritesPages) {
      setFavoritesPages(JSON.parse(favoritesPages));
    }
  }, []);

  const isFavoritePage = (pageName) => {
    return favoritesPages.includes(pageName);
  };

  const getInitials = (username) => {
    const splittedName = username.split(/\s/g);
    if (splittedName.length) {
      return splittedName.length > 1
        ? splittedName[0].charAt(0) + splittedName[splittedName.length - 1].charAt(0)
        : username.charAt(0);
    }
  };

  const setSettings = useCallback(async () => {
    const { username, dashboards, dashboard } = user;

    setProfileInitials(getInitials(username));

    if (dashboards && dashboards.length > 0) {
      setShowOldDashboardMenuItem(true);
    } else if (dashboard) {
      setShowOldDashboardMenuItem(true);
    }

    setShowIntelipostAnalysis(await companyHasIntelipostConnector());
    setEnabledAdTextCreatorFeature(featureIsEnabled(featureType.AD_TEXT_CREATOR));
    setEnabledSmartReportMinimumStockFeature(featureIsEnabled(featureType.SMART_REPORT_MINIMUM_STOCK));
  }, [user]);

  const isCurrentRoute = (route) => {
    return window.location.href.endsWith(route);
  };

  const isAnyCurrentRouteMatching = (routes) => {
    return routes.some((route) => window.location.href.endsWith(route));
  };

  const getPagesRoutesByCategory = (category) => {
    const allPagesSettings = { ...pageSettings, ...deprecatedPagesSettings };
    return Object.keys(allPagesSettings)
      .filter((page) => allPagesSettings[page].category === category)
      .map((page) => allPagesSettings[page].route);
  };

  const getPageRouteByName = (name) => {
    const allPagesSettings = { ...pageSettings, ...deprecatedPagesSettings };
    return Object.values(allPagesSettings).find((page) => page.name === name).route;
  };

  const isNewPage = (item) => {
    if (item.hasSubCategories) {
      return false;
    }

    const allPagesSettings = { ...pageSettings, ...deprecatedPagesSettings };
    return Object.values(allPagesSettings).find((page) => page.name === item.name).isNew;
  };

  const isDeprecatedPage = (item) => {
    if (item.hasSubCategories) {
      return false;
    }

    const allPagesSettings = { ...pageSettings, ...deprecatedPagesSettings };
    return Object.values(allPagesSettings).find((page) => page.name === item.name).isDeprecated;
  };

  const isSelectedMenuItem = (item) => {
    if (item.hasSubCategories) {
      return isAnyCurrentRouteMatching(getPagesRoutesByCategory(item.name));
    }

    return isCurrentRoute(getPageRouteByName(item.name));
  };

  const onMouseEnterMenuItem = (item) => {
    setHoveredMenuItem(item.name);

    if (item.name === PAGES.FAVORITE) {
      setCurrentSubCategoriesMenuItem(
        Object.values(pageSettings).filter((page) => isFavoritePage(page.name))
      );
    } else {
      setCurrentSubCategoriesMenuItem(
        Object.values(pageSettings).filter((page) => {
          const isSubCategory = page.category === item.name;
          const intelipostAnalysis = [
            pageSettings.logisticAddressesWithDeliveryFailures.name,
            pageSettings.logisticIntelipostMostCommunDeliveryTime.name,
          ];

          if (isSubCategory && page.name === pageSettings.adTextCreator.name) {
            return enabledAdTextCreatorFeature;
          } else if (isSubCategory && page.name === pageSettings.smartReportMinimumStock.name) {
            return enabledSmartReportMinimumStockFeature;
          } else if (isSubCategory && intelipostAnalysis.includes(page.name)) {
            return showIntelipostAnalysis;
          }

          return isSubCategory;
        })
      );
    }
  };

  const onClickMenuItem = (item, isExpanded) => {
    if (!isExpanded) {
      setIsExpanded(true);
      return;
    }

    if (!item.hasSubCategories) {
      navigateToPage(getPageRouteByName(item.name));
    }
  };

  const handleTransitionEnd = () => {
    if (isExpanded) {
      setIsTransitioning(false);
    }
  };

  useEffect(() => {
    if (!isExpanded) {
      setIsTransitioning(true);
    }
  }, [isExpanded]);

  useEffect(() => {
    setMobileModeActive(window.innerWidth < MOBILE_BREAKPOINT);
    window.addEventListener("resize", () => {
      setMobileModeActive(window.innerWidth < MOBILE_BREAKPOINT);
    });

    setCompanyFeatures();
    setSettings();
    setFavoritesPagesFromLocalStorage();
  }, [setSettings, setFavoritesPagesFromLocalStorage]);

  return (
    <>
      {((mobileModeActive && isExpanded) || !mobileModeActive) && (
        <SidebarContainer
          isExpanded={isExpanded && !mobileModeActive}
          expandedWidth={MENU_EXPANDED_WIDTH}
          normalWith={MENU_WIDTH}
          onTransitionEnd={handleTransitionEnd}
        >
          {!mobileModeActive && (
            <SidebarHeader>
              <Logo src={isExpanded ? originalBisoLogo : smallBisoLogo} alt="Biso" isExpanded={isExpanded} />
              {!isExpanded && (
                <ButtonOpenMenu onClick={() => setIsExpanded(!isExpanded)}>
                  <MdKeyboardDoubleArrowRight />
                </ButtonOpenMenu>
              )}
              {isExpanded && (
                <ButtonCloseMenu onClick={() => setIsExpanded(!isExpanded)}>
                  {t('common.minimize')} <IoIosArrowBack size={24} />
                </ButtonCloseMenu>
              )}
            </SidebarHeader>
          )}
          {mobileModeActive && (
            <SidebarHeader isMobile={mobileModeActive}>
              <ButtonMobileMenu onClick={() => setIsExpanded(!isExpanded)}>
                <MobileMenuIcon />
              </ButtonMobileMenu>
            </SidebarHeader>
          )}
          <SidebarMenu isExpanded={isExpanded && !mobileModeActive} isMobile={mobileModeActive}>
            {menuItems.map((item) => {
              if (item.name === deprecatedPagesSettings.oldDashboard.name && !showOldDashboardMenuItem) {
                return null;
              }

              return (
                <BisoTooltip
                  key={item.name}
                  title={item.name}
                  placement="right"
                  disabled={isExpanded || mobileModeActive}
                  manualOpen={
                    (isExpanded || mobileModeActive || isTransitioning) && hoveredMenuItem === item.name
                  }
                >
                  <SidebarItem
                    onMouseEnter={(_) => onMouseEnterMenuItem(item)}
                    onMouseLeave={(_) => setHoveredMenuItem(null)}
                    isSelected={isSelectedMenuItem(item)}
                    onClick={(_) => onClickMenuItem(item, isExpanded)}
                  >
                    <MenuIcon
                      isExpanded={isExpanded && !mobileModeActive}
                      isSelected={isSelectedMenuItem(item)}
                    >
                      {item.icon(isSelectedMenuItem(item))}
                    </MenuIcon>
                    {isExpanded && !mobileModeActive && !isTransitioning && item.name}
                    {isExpanded && !mobileModeActive && !isTransitioning && isNewPage(item) && (
                      <MenuItemTagNewFeature>Novo</MenuItemTagNewFeature>
                    )}
                    {isExpanded && !mobileModeActive && !isTransitioning && isDeprecatedPage(item) && (
                      <MenuItemTagDeprecated>Descontinuado</MenuItemTagDeprecated>
                    )}
                    {isExpanded && item.hasSubCategories && (
                      <IoIosArrowForward
                        size={18}
                        style={{
                          marginLeft: "auto",
                          visibility: item.hasSubCategories ? "visible" : "hidden",
                          color: "#1A1D1F",
                        }}
                      />
                    )}
                    {item.hasSubCategories && hoveredMenuItem === item.name && isExpanded && (
                      <SubMenuContainer isMobile={mobileModeActive}>
                        {currentSubCategoriesMenuItem.map((subItem) => (
                          <SubMenuItemContainer key={subItem.name} isSelected={isCurrentRoute(subItem.route)}>
                            <SubMenuItem
                              onClick={() => navigateToPage(subItem.route)}
                              isSelected={isCurrentRoute(subItem.route)}
                            >
                              {subItem.name}
                              {subItem.isNew && <SubMenuItemTagNewFeature>Novo</SubMenuItemTagNewFeature>}
                              {subItem.isDeprecated && (
                                <SubMenuItemTagDeprecated>Descontinuado</SubMenuItemTagDeprecated>
                              )}
                            </SubMenuItem>
                            {isFavoritePage(subItem.name) ? (
                              <BisoTooltip title={t('menu.remove_favorite')}>
                                <ButtonFavorite
                                  onClick={(_) => removePageFromFavorites(subItem.name)}
                                  isSelected={isCurrentRoute(subItem.route)}
                                >
                                  <FavoritesIconFilled />
                                </ButtonFavorite>
                              </BisoTooltip>
                            ) : (
                              <BisoTooltip title={t('menu.add_favorite')}>
                                <ButtonFavorite onClick={(_) => addPageToFavorites(subItem.name)}>
                                  <FavoritesIcon />
                                </ButtonFavorite>
                              </BisoTooltip>
                            )}
                          </SubMenuItemContainer>
                        ))}
                        {item.name === PAGES.FAVORITE && currentSubCategoriesMenuItem.length === 0 && (
                          <NoFavorites>{t('menu.add_favorite_pages')}</NoFavorites>
                        )}
                      </SubMenuContainer>
                    )}
                  </SidebarItem>
                </BisoTooltip>
              );
            })}
          </SidebarMenu>
          <SidebarActions>
            {mobileModeActive && (
              <>
                <BisoTooltip title={t('appBar.view_profile')}>
                  <Profile
                    isSelected={isCurrentRoute(routes.profile)}
                    onClick={(_) => navigateToPage(routes.profile)}
                    style={{
                      margin: "8px 10px 0px 10px",
                    }}
                  >
                    {profileInitials}
                  </Profile>
                </BisoTooltip>
                <BisoTooltip title={t('appBar.data_update')}>
                  <IconContainer
                    isSelected={isCurrentRoute(routes.dataSyncInfo)}
                    onClick={(_) => navigateToPage(routes.dataSyncInfo)}
                    style={{
                      margin: "8px 10px 0px 10px",
                    }}
                  >
                    <IconImage
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                      src={connectorLastSearchIcon}
                      alt="Data sync"
                    />
                  </IconContainer>
                </BisoTooltip>
              </>
            )}
            <FirstSteps
              hiddenSetupResumeText={!isExpanded || mobileModeActive || isTransitioning}
              styles={{
                padding: isExpanded && !mobileModeActive ? "0 20px" : "0 10px",
                marginTop: mobileModeActive ? "8px" : null,
              }}
            />
            <ButtonLogout onClick={handleSignOut}>
              <MenuIcon isExpanded={isExpanded}>
                <TbLogout size={18} color="#FFFFFF" />
              </MenuIcon>
              {isExpanded && !mobileModeActive && t('menu.log_out')}
            </ButtonLogout>
          </SidebarActions>
        </SidebarContainer>
      )}
      <AppBar
        mobileModeActive={mobileModeActive}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        menuExpandedWidth={MENU_EXPANDED_WIDTH}
        menuWidth={MENU_WIDTH}
        isCurrentRoute={isCurrentRoute}
        isAnyCurrentRouteMatching={isAnyCurrentRouteMatching}
        navigateToPage={navigateToPage}
        returnToLastScreen={returnToLastScreen}
        profileInitials={profileInitials}
        pageSettings={pageSettings}
        lastScreenName={lastScreenName}
      />
      {(!mobileModeActive || mobileModeActive) && !isExpanded && <SupportButton />}
      <InfoBarFacebookOAuthReconnect />
      {user && user.type === "profile" && <InfoBarInstallApp />}
      {user && user.type === "company" && <InfoBarProfileLogin />}
      {/* <ModalPageBisoAdvertise /> */}
    </>
  );
};

export default Menu;
